<template>
  <div id="app">
    <div id="nav">
      <div id="back-button-container">
        <router-link to="/"><img id="nav-back-button"></router-link>
      </div>
      <div id="logo-container">
        <router-link to="/"><img src="@/assets/signal-flow-trainer-logo.svg" alt="signal flow trainer logo" id="logo"></router-link>
      </div>
      <!-- <router-link to="/Lessons">Lessons</router-link>
      <router-link to="/Lesson1">Lesson 1</router-link>
      <router-link to="/Lesson2">Lesson 2</router-link> -->
      <div id="custom-select-container">
        <a href="https://docs.google.com/forms/d/1Rj9WUBnaotsGNhxBZE5TRdU3aqRhSdj26gXO9doNp0E/edit"><button class="feedback-button">feedback</button></a>
        <!-- <select name="lesson-choice"> -->
        <!-- <select>
          <option value="0">All Lessons</option>
          <option value="1">Lesson 1</option>
          <option value="2">Lesson 2</option>
        </select> -->
      </div>
    </div>
    <!-- <div class="audio-controller-container">
      <button v-if="audio"><img src="/assets/audio-on.svg" alt="audio on icon"></button>
      <button v-else><img src="/assets/audio-off.svg" alt="audio on icon"></button>
    </div> -->
    <router-view/>
  </div>
</template>

<script>

export default {
  data () {
    return {
      bedLoopAudio: new Audio('/sounds/Bed1.mp3'),
      loopPlay: this.$store.getters.bedLoopPlay
    }
  },
  // methods: {
  //   stopBedLoop () {
  //     this.bedLoopAudio.pause()
  //   },
  //   playBedLoop () {
  //     this.bedLoopAudio.play()
  //     this.bedLoopAudio.loop = true
  //   }
  // },
  // watch: {
  //   loopPlay: function () {
  //     if (this.$store.getters.bedLoopPlay === 1) {
  //       console.log(this.$store.getters.bedLoopPlay)
  //       this.playBedLoop()
  //       console.log(this.$store.getters.bedLoopPlay)
  //     } else {
  //       console.log(this.$store.getters.bedLoopPlay)
  //       this.stopBedLoop()
  //       console.log(this.$store.getters.bedLoopPlay)
  //     }
  //   }
  // },
  mounted () {
    if (navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Firefox/i)) {
      this.$router.push('/notSupported')
    }
  }
}
</script>

<style>

.feedback-button {
  margin: 14px;
  width: 140px;
  height: 40px;
  border: 2px solid black;
  border-radius: 6px;
  letter-spacing: 4.72px;
  background-color: #ffffff;
  color: #080808;
  font-size: 16px;
  outline: none;
}

.feedback-button:hover {
  background-color: #00CD5D;
  color: white;
  cursor: pointer;
}

.feedback-button:active {
  background-color: #00B854;
  color: white;
}

.audio-controller-container {
  margin-top: 120px;
}

#app {
  font-family: 'Avenir', Helvetica, Raleway, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

#nav {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 8px #20202312;
  /* make nav sticky */
  position: fixed;
  top: 0;
  width: 100vw;
  align-items: center;
}

#nav #back-button-container {
  flex: 0 1 15%;
}

#nav #logo-container {
  flex: 0 1 70%;
}

#nav #custom-select-container {
  flex: 0 1 15%;
}

#nav-back-button {
  height: 100%;
}

#logo {
  height: 100%;
}

/* #nav #lesson-selector-dropdown {
  justify-content: right;
} */

#nav a {
  font-weight: bold;
  color: #2c3e50;
  padding: 0px 30px 0px 30px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#logo {
  content:url("/assets/signal-flow-trainer-logo.svg");
}

#nav-back-button {
  content:url("/assets/back-button.svg");
}

@media (max-width: 1068px) {

}

@media (max-width: 735px) {

  #logo {
  content:url("/assets/sft-logo-mobile.svg");
  }

  #nav-back-button {
  content:url("/assets/back-button-mobile.svg");
  }

}

@media (max-width: 360px) {

  #logo {
  content:url("/assets/sft-logo-mobile.svg");
  }

  #nav-back-button {
  content:url("/assets/back-button-arrow-only.svg");
  }

}

</style>
