import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ex1ptA: 0,
    ex1ptB: 0,
    ex1ptC: 0,
    ex1ptD: 0,
    ex2ptA: 0,
    ex2ptB: 0,
    ex2ptC: 0,
    ex3ptA: 0,
    ex3ptB: 0,
    ex3ptC: 0,
    ex4ptA: 0,
    ex4ptB: 0,
    ex4ptC: 0,
    ex5ptA: 0,
    ex5ptB: 0,
    ex5ptC: 0,
    ex6ptA: 0,
    ex6ptB: 0,
    ex6ptC: 0,
    bedLoopPlay: 1,
    slideId: 1
  },
  mutations: {
    ex1ptAcomplete (state) {
      state.ex1ptA = 1
    },
    ex1ptBcomplete (state) {
      state.ex1ptB = 1
    },
    ex1ptCcomplete (state) {
      state.ex1ptC = 1
    },
    ex1ptDcomplete (state) {
      state.ex1ptD = 1
    },
    ex2ptAcomplete (state) {
      state.ex2ptA = 1
    },
    ex2ptBcomplete (state) {
      state.ex2ptB = 1
    },
    ex2ptCcomplete (state) {
      state.ex2ptC = 1
    },
    ex3ptAcomplete (state) {
      state.ex3ptA = 1
    },
    ex3ptBcomplete (state) {
      state.ex3ptB = 1
    },
    ex3ptCcomplete (state) {
      state.ex3ptC = 1
    },
    ex4ptAcomplete (state) {
      state.ex4ptA = 1
    },
    ex4ptBcomplete (state) {
      state.ex4ptB = 1
    },
    ex4ptCcomplete (state) {
      state.ex4ptC = 1
    },
    ex5ptAcomplete (state) {
      state.ex5ptA = 1
    },
    ex5ptBcomplete (state) {
      state.ex5ptB = 1
    },
    ex5ptCcomplete (state) {
      state.ex5ptC = 1
    },
    ex6ptAcomplete (state) {
      state.ex6ptA = 1
    },
    ex6ptBcomplete (state) {
      state.ex6ptB = 1
    },
    ex6ptCcomplete (state) {
      state.ex6ptC = 1
    },
    loopPlay (state) {
      state.bedLoopPlay = 1
    },
    loopStop (state) {
      state.bedLoopPlay = 0
    },
    resetSlideId (state) {
      state.slideId = 1
    },
    incrementSlideId (state) {
      state.slideId++
    },
    decrementSlideId (state) {
      state.slideId--
    }
  },
  actions: {

  },
  getters: {
    ex1ptA: state => state.ex1ptA,
    ex1ptB: state => state.ex1ptB,
    ex1ptC: state => state.ex1ptC,
    ex1ptD: state => state.ex1ptD,
    ex2ptA: state => state.ex2ptA,
    ex2ptB: state => state.ex2ptB,
    ex2ptC: state => state.ex2ptC,
    ex3ptA: state => state.ex3ptA,
    ex3ptB: state => state.ex3ptB,
    ex3ptC: state => state.ex3ptC,
    ex4ptA: state => state.ex4ptA,
    ex4ptB: state => state.ex4ptB,
    ex4ptC: state => state.ex4ptC,
    ex5ptA: state => state.ex5ptA,
    ex5ptB: state => state.ex5ptB,
    ex5ptC: state => state.ex5ptC,
    ex6ptA: state => state.ex6ptA,
    ex6ptB: state => state.ex6ptB,
    ex6ptC: state => state.ex6ptC,
    bedLoopPlay: state => state.bedLoopPlay,
    slideId: state => state.slideId
  }
})
