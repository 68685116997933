import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'allLessonsDisplay',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/allLessonDisplay.vue')
    },
    {
      path: '/Lesson1',
      name: 'Lesson1',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson1.vue')
    },
    {
      path: '/Lesson1Exercise',
      name: 'Exercise1',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise1.vue')
    },
    {
      path: '/Lesson1AExercise',
      name: 'Exercise1A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise1A.vue')
    },
    {
      path: '/Lesson1BExercise',
      name: 'Exercise1B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise1B.vue')
    },
    {
      path: '/Lesson1CExercise',
      name: 'Exercise1C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise1C.vue')
    },
    {
      path: '/Lesson1DExercise',
      name: 'Exercise1D',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise1D.vue')
    },
    {
      path: '/Lesson2',
      name: 'Lesson2',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson2.vue')
    },
    {
      path: '/certificate',
      name: 'certificate',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate.vue')
    },
    {
      path: '/Lesson2AExercise',
      name: 'Exercise2A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise2A.vue')
    },
    {
      path: '/Lesson2BExercise',
      name: 'Exercise2B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise2B.vue')
    },
    {
      path: '/Lesson2CExercise',
      name: 'Exercise2C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise2C.vue')
    },
    {
      path: '/certificate2',
      name: 'certificate2',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate2.vue')
    },
    {
      path: '/Lesson3',
      name: 'Lesson3',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson3.vue')
    },
    {
      path: '/Lesson3AExercise',
      name: 'Exercise3A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise3A.vue')
    },
    {
      path: '/Lesson3BExercise',
      name: 'Exercise3B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise3B.vue')
    },
    {
      path: '/Lesson3CExercise',
      name: 'Exercise3C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise3C.vue')
    },
    {
      path: '/certificate3',
      name: 'certificate3',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate3.vue')
    },
    {
      path: '/Lesson4',
      name: 'Lesson4',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson4.vue')
    },
    {
      path: '/Lesson4AExercise',
      name: 'Exercise4A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise4A.vue')
    },
    {
      path: '/Lesson4BExercise',
      name: 'Exercise4B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise4B.vue')
    },
    {
      path: '/Lesson4CExercise',
      name: 'Exercise4C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise4C.vue')
    },
    {
      path: '/certificate4',
      name: 'certificate4',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate4.vue')
    },
    {
      path: '/Lesson5',
      name: 'Lesson5',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson5.vue')
    },
    {
      path: '/Lesson5AExercise',
      name: 'Exercise5A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise5A.vue')
    },
    {
      path: '/Lesson5BExercise',
      name: 'Exercise5B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise5B.vue')
    },
    {
      path: '/Lesson5CExercise',
      name: 'Exercise5C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise5C.vue')
    },
    {
      path: '/certificate5',
      name: 'certificate5',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate5.vue')
    },
    {
      path: '/Lesson6',
      name: 'Lesson6',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Lesson6.vue')
    },
    {
      path: '/Lesson6AExercise',
      name: 'Exercise6A',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise6A.vue')
    },
    {
      path: '/Lesson6BExercise',
      name: 'Exercise6B',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise6B.vue')
    },
    {
      path: '/Lesson6CExercise',
      name: 'Exercise6C',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Exercise6C.vue')
    },
    {
      path: '/certificate6',
      name: 'certificate6',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/certificate6.vue')
    },
    {
      path: '/notSupported',
      name: 'notSupported',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/notSupportedView.vue')
    },
    {
      path: '/credits',
      name: 'credits',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/creditsView.vue')
    }
  ]
})
